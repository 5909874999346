import { documentReady } from "./utils/dom"
import getCookie from "./utils/getCookie"

function setupBanner(name) {
  const containerElement = document.getElementById(name)

  if (!containerElement) {
    return
  }

  const cookieName = "hideBanner_" + name

  const hideBanner = getCookie(cookieName) === "true"

  if (hideBanner) {
    containerElement.classList.add("hide")
    return
  }

  const closeButton = containerElement.querySelector(".close")

  if (!closeButton) {
    return
  }

  const in10Days = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)

  closeButton.addEventListener("click", () => {
    document.cookie = `${cookieName}=true; expires=${in10Days.toUTCString()}`
    containerElement.classList.add("hide")
  })
}

documentReady(() => {
  const banners = [
    "platform_budget_banner",
    "health_friday_banner",
    "health_friday_banner_2",
    "nikolauf_2024_banner_2",
  ]
  banners.forEach((banner) => setupBanner(banner))
})
